
import Vue from "vue";
export default Vue.extend({
  name: "sendgrid-email-status-indicator",
  props: {
    activities: {
      //sendgrid activities from db from which to derive the status
      type: Array
    }
  },
  mounted() {
    this.activities.sort(function(a: any, b: any) {
      return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
    });
  },
  computed: {
    status(): string {
      const lastActivity: any = this.activities[0];
      return lastActivity.data.event || "processing"; //probably don't hardcode processed, introduce new status with cool color like blue to indicate pending-confirmation
    }
  }
});
